<template>
  <section id="prospect">
    <b-card no-body>
      <b-tabs card>
        <b-tab title="Customer Information">
          <b-row>
            <b-col md="5" class="border-right">
              <b-row>
                <b-col md="8" lg="6">
                  <div class="detail-info">
                    <span>Customer Name</span>
                    <h4>{{customerInformation.tp_client_name || '-'}} - {{ customerInformation.tp_client_type == 'residencial' ? 'Residential' : 'Company' }}</h4>
                  </div>
                  <div class="detail-info" v-if="customerInformation.tp_client_type == 'company'">
                    <span>Company Name</span>
                    <h4>{{customerInformation.tp_client_company || '-'}}</h4>
                  </div>
                </b-col>
                <b-col md="8">
                  <div class="detail-info">
                    <span>Contact</span>
                    <h4 v-if="customerInformation.tp_client_email"><a :href="'mailto:' + customerInformation.tp_client_email" class="contact-info"><i class="ti-email"></i><span>{{ customerInformation.tp_client_email || '-'}}</span></a></h4>
                    <h4 v-if="customerInformation.tp_client_phone"><a :href="'https://web.whatsapp.com/send?phone='+customerInformation.tp_client_phone.replace('08', '628')" target="child" class="contact-info"><i class="fab fa-whatsapp"></i><span>{{ customerInformation.tp_client_phone || '-'}}</span></a></h4>
                  </div>
                </b-col>
                <b-col md="8">
                  <div class="detail-info">
                    <span>Address</span>
                    <h4>{{ customerInformation.tp_client_address || '-' }}</h4>
                  </div>
                </b-col>
                <b-col md="8">
                  <div class="detail-info">
                    <span>Source</span>
                    <h4>{{ $parent.prospectSrc(customerInformation.tp_source).label || '-'}}</h4>
                  </div>
                </b-col>
                <b-col md="8">
                  <div class="detail-info">
                    <span>Contact Via</span>
                    <h4>{{ $parent.contactMedia(customerInformation.tp_contact_via).label || '-'}}</h4>
                  </div>
                </b-col>
              </b-row>
            </b-col>
            <b-col md="5">
              <b-row>
                <b-col md="8">
                  <div class="detail-info">
                    <span>Prospect Date</span>
                    <h4>{{ customerInformation.tp_date | moment("LLLL") }}</h4>
                  </div>
                </b-col>
                <b-col md="8" v-if="customerInformation.tp_closing_date">
                  <div class="detail-info">
                    <span>Closing Date</span>
                    <h4>{{ customerInformation.tp_closing_date | moment("LL") }}</h4>
                  </div>
                </b-col>
                <b-col md="8">
                  <div class="detail-info">
                    <span>Purpose</span>
                    <h4>{{ customerInformation.tp_keperluan || '-' }}</h4>
                  </div>
                </b-col>
                <b-col md="8">
                  <div class="detail-info">
                    <span>General Information</span>
                    <h4>{{customerInformation.tp_general_information || '-'}}</h4>
                  </div>
                </b-col>
                <b-col md="8">
                  <div class="detail-info">
                    <span>Partner</span>
                    <h4>{{customerInformation.mp_name || '-'}}</h4>
                  </div>
                </b-col>
                <b-col md="8">
                  <div class="detail-info">
                    <span>Project Schedule</span>
                    <h4>{{customerInformation.tp_start_date | moment('LL')}} <small>s.d.</small> {{ customerInformation.tp_end_date | moment('LL') }}</h4>
                  </div>
                </b-col>
                <b-col md="8" v-if="customerInformation.tp_status_prospect == 'P'">
                  <div class="detail-info">
                    <span>Pending Reason</span>
                    <h4>{{customerInformation.tp_pending_reason || '-'}}</h4>
                  </div>
                </b-col>
                <b-col md="8" v-else-if="customerInformation.tp_status_prospect == 'R'">
                  <div class="detail-info">
                    <span>Reject Reason</span>
                    <h4>{{customerInformation.tp_reject_reason || '-'}}</h4>
                  </div>
                </b-col>
                <b-col md="8">
                  <div class="detail-info">
                    <span>Status</span>
                    <div>
                      <b-badge :variant="$parent.customerBadge[customerInformation.tp_status_customer].color">
                        {{ $parent.customerBadge[customerInformation.tp_status_customer].word }}
                      </b-badge>
                    </div>
                  </div>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </b-tab>
        <b-tab title="Follow Up History">
          <template v-if="$parent.lengthArr(followUpHistory)">
            <b-row>
              <b-col lg="8" md="10">
                <div class="timeline">
                  <div class="timeline-box" v-for="(value, key) in followUpHistory" :key="key">
                    <div class="timeline-head">
                      <h4 class="timeline__title">{{ value.tfu_date | moment('LL') }}</h4>
                      <b-badge v-if="value.tfu_status_done == 'done'" :variant="$parent.prospectBadge[value.tfu_status].color">
                        {{ $parent.prospectBadge[value.tfu_status].word }}
                      </b-badge>
                      <p v-else>Not Finished yet</p>
                      <span v-if="value.tfu_status_done == 'done'">Followed Up by: {{ value.tfu_person }}</span>
                    </div>
                    <div class="timeline-body">
                      <p>{{ value.tfu_result }}</p>
                      <p v-if="value.tfu_pending_reason">Pending Reason: {{ value.tfu_pending_reason }}</p>
                    </div>
                  </div>
                </div>
              </b-col>
            </b-row>
          </template>
          <template v-else>
            <h3>Follow Up History is Empty.</h3>
          </template>
        </b-tab>
        <b-tab title="Survey History">
          <template v-if="$parent.lengthArr(surveyHistory)">
            <b-row>
              <b-col lg="6" md="8">
                <div class="detail-info">
                  <span>Survey Address</span>
                  <h4>{{ (surveyHistory[0] || {tss_address: ''}).tss_address }}</h4>
                </div>
              </b-col>
            </b-row>
            <b-row>
              <b-col lg="8" md="10">
                <div class="timeline">
                  <div class="timeline-box" v-for="(value, index) in surveyHistory" :key="index">
                    <div class="timeline-head">
                      <h4 class="timeline__title d-block">{{ value.tss_date | moment('LLL') }}</h4>                      
                      <template v-for="(v, k) in value.tss_image">
                        <b-button v-if="v" size="sm" variant="outline-info" @click="setLightBox(v)" :key="k" class="mt-2 mb-2" :class="k != 0 ? 'ml-2' : ''">                          
                          <i class="fas fa-image"></i>
                          See Photo
                        </b-button>
                      </template>
                      <p>Surveyor: {{ value.ms_name }}</p>
                    </div>
                    <div class="timeline-body">
                      <p>{{value.tss_result}}</p>
                    </div>
                  </div>
                </div>
              </b-col>
            </b-row>
          </template>
          <template v-else>
            <h3>Survey History is Empty.</h3>
          </template>
        </b-tab>
       <b-tab title="Payment">
          <b-row>
            <b-col md="6" lg="7">
              <b-row>
                <template v-if="customerInformation.tp_finish_payment == 'Y'">
                  <b-col md="6">
                    <div class="detail-info">
                      <span>Project Worth</span>
                      <h4>{{$parent.formatRupiah(customerInformation.tp_total_price)}}</h4>

                      <div>
                        <b-badge variant="success" v-if="customerInformation.tp_finish_payment == 'Y'">Paid</b-badge>
                        <b-badge variant="warning" v-else>Payment Ongoing</b-badge>
                      </div>
                    </div>                  
                  </b-col>
                </template>
                <template v-else>
                  <b-col md="6" @click="openModalEditProjWorth(customerInformation)" v-b-tooltip.hover="'Click to edit Project Worth'" class="c-pointer">
                    <div class="detail-info">
                      <span>Project Worth</span>
                      <h4>{{$parent.formatRupiah(customerInformation.tp_total_price)}}</h4>

                      <div>
                        <b-badge variant="success" v-if="customerInformation.tp_finish_payment == 'Y'">Paid</b-badge>
                        <b-badge variant="warning" v-else>Payment Ongoing</b-badge>
                      </div>
                    </div>                  
                  </b-col>
                </template>

                <b-col md="6">
                  <div class="detail-info">
                    <span>Remaining Payment</span>
                    <h4>{{$parent.formatRupiah(remaining)}}</h4>
                  </div>
                </b-col>

                <b-col md="6">
                  <div class="detail-info">
                    <span>Revenue Partner</span>
                    <h4>{{revenue && $parent.formatRupiah(revenue.partner)}}</h4>

                    <small class="d-block font-weight-bold mt-1">({{ revenuePercent && revenuePercent.partner }}%)</small>
                  </div>
                </b-col>

                <b-col md="6">
                  <div class="detail-info">
                    <span>Revenue Company</span>
                    <h4>{{revenue && $parent.formatRupiah(revenue.bu)}}</h4>

                    <small class="d-block font-weight-bold mt-1">({{ revenuePercent && revenuePercent.bu }}%)</small>
                  </div>
                </b-col>

                <b-col md="12">
                  <h4 class="font-weight-bold">Partner</h4>
                </b-col>

                <b-col md="6">
                  <div class="detail-info">
                    <span>Receive</span>
                    <h4>{{$parent.formatRupiah(received.partner)}}</h4>
                  </div>
                </b-col>
                <b-col md="6">
                  <div class="detail-info">
                    <span>Account Receivable</span>
                    <h4>{{$parent.formatRupiah(accountsReceivable.partner)}}</h4>
                  </div>
                </b-col>
                

                <b-col md="12">
                  <h4 class="font-weight-bold">Company</h4>
                </b-col>

                <b-col md="6">
                  <div class="detail-info">
                    <span>Receive</span>
                    <h4>{{$parent.formatRupiah(received.bu)}}</h4>
                  </div>
                </b-col>
                
                <b-col md="6">
                  <div class="detail-info">
                    <span>Account Receivable</span>
                    <h4>{{$parent.formatRupiah(accountsReceivable.bu)}}</h4>
                  </div>
                </b-col>

                <b-col md="6">
                  <div class="detail-info">
                    <span>Bailout / Talangan</span>
                    <h4>{{$parent.formatRupiah(customerInformation.tp_bailout)}}</h4>
                  </div>
                </b-col>
              </b-row>
            </b-col>
            <b-col md="6" lg="5" v-if="customerInformation.tp_status_customer != 'F' && customerInformation.tp_finish_payment != 'Y'" class="border-left">
              <template v-if="$parent.moduleRole('update_payment')">
                <validation-observer v-if="customerInformation.tp_finish_payment != 'Y'" mode="passive" v-slot="{ handleSubmit }" ref="VFormUpdatePayment">
                  <b-form @submit.prevent="handleSubmit(updatePayment())">
                    <h4 class="font-weight-bold">Make Payment</h4>
                    <b-form-row>                      
                      <b-col cols="12">
                        <b-form-group label="Amount" label-for="payAmount">
                          <b-input-group prepend="Rp.">
                            <money id="payAmount" :disabled="hasBailout" @input="setPriceCompany(dataModal)" v-model="dataModal.tcp_amount" v-bind="$parent.maskCurrency" placeholder="cth: 600.00" class="form-control"></money>
                          </b-input-group>
                          <VValidate mode="passive" name="Amount" v-model="dataModal.tcp_amount" :rules="{ required: true, min_value: 0, max_value: remaining }" />
                        </b-form-group>
                        <b-form-group label="Payment Date" label-for="rescheduleDate">
                          <date-picker type="date" value-type="format" format="YYYY-MM-DD" id="rescheduleDate" prefix-class="crm" v-model="dataModal.tcp_date" :disabled-date="$parent.notAfterToday"/>
                          <VValidate name="Payment Date" rules="required" v-model="dataModal.tcp_date" />
                        </b-form-group>                        
                        <b-form-group label="Description" label-for="proDP">
                          <b-form-input id="description" v-model="dataModal.tcp_description" placeholder="e.g DP 50%" />
                          <VValidate name="Description" :rules="{ required: true, min: 2, max: 128 }" v-model="dataModal.tcp_description" />
                        </b-form-group>
                        <b-form-group>
                          <b-form-checkbox @input="setBailout(dataModal)" v-model="hasBailout">Bailout / Talangan</b-form-checkbox>
                        </b-form-group>
                        <div class="border p-3 mb-3">
                          <h5 class="font-weight-bold">Payment Shares</h5>
                          <b-form-row>
                            <b-col md="6">
                              <b-form-group label="Partner Share" label-for="proPartShare" class="mb-0">
                                <b-input-group prepend="Rp.">
                                  <money id="proPartShare" @input="setPriceCompany(dataModal)" v-model="dataModal.tcp_amount_partner" v-bind="$parent.maskCurrency" placeholder="cth: 600.00" class="form-control"></money>
                                </b-input-group>
                                <VValidate mode="passive" name="Partner Share" v-model="dataModal.tcp_amount_partner" :rules="{ required: true, min_value: 0, max_value: +revenue.partner - +received.partner }" />
                              </b-form-group>
                            </b-col>
                            <b-col md="6">
                              <b-form-group label="Company Share" label-for="proCompShare" class="mb-0">
                                <b-input-group prepend="Rp.">
                                  <money id="proCompShare" disabled v-model="dataModal.tcp_amount_bu" v-bind="$parent.maskCurrency" placeholder="cth: 600.00" class="form-control"></money>
                                </b-input-group>
                                <VValidate mode="passive" name="Company Share" v-model="dataModal.tcp_amount_bu" :rules="{ required: true, max_value: dataModal.tcp_amount }" />
                              </b-form-group>
                            </b-col>
                          </b-form-row>
                        </div>

                        <b-form-group>
                          <b-form-checkbox v-if="!hasBailout" v-model="hasLossCost">Has Loss Cost</b-form-checkbox>
                        </b-form-group>

                        <template v-if="hasLossCost">
                          <b-form-group label="Loss Cost" label-for="payLossCost">
                            <b-input-group prepend="Rp.">
                              <money id="payLossCost" v-model="dataModal.tcp_loss_cost" v-bind="$parent.maskCurrency" placeholder="cth: 600.00" class="form-control"></money>
                            </b-input-group>                  
                          </b-form-group>

                          <b-form-group label="Reason Loss Cost" label-for="reasonLossCost">                  
                            <b-form-textarea id="reasonLossCost" v-model="dataModal.tcp_reason_loss_cost" rows="5" placeholder="e.g Bayar ganti rugi" />
                            <VValidate name="Reason Loss Cost" v-model="dataModal.tcp_reason_loss_cost" :rules="{ min: 5, max: 256, }" />                  
                          </b-form-group>
                        </template>
                      </b-col>
                      <b-col md="12">
                        <b-button type="submit" variant="info btn-rounded">Submit Payment</b-button>
                      </b-col>
                    </b-form-row>
                  </b-form>
                </validation-observer>
              </template>
            </b-col>
            <b-col md=12><hr></b-col>
            <b-col md=12>
              <div class="detail-info">
                <span>Payment History</span>
                <b-table-simple small bordered class="payment-table">
                  <b-thead>
                    <b-tr>
                      <b-th width="30%">Desc</b-th>
                      <b-th colspan="2">Amount</b-th>
                      <b-th>Paid On</b-th>
                      <b-th v-if="$parent.moduleRole('delete_payment') || $parent.moduleRole('edit_payment')">Action</b-th>
                    </b-tr>
                  </b-thead>
                  <b-tbody>
                  <template v-for="(value, index) in historyPayment">
                      <b-tr :key="'A' + index">
                        <b-td rowspan="2">
                          <p>{{ value.tcp_description }}</p>                              
                        </b-td>
                        <b-td colspan="2"> {{value.tcp_type == 'EXP' ? '-' : ''}} {{ $parent.formatRupiah(value.tcp_amount) }}</b-td>
                        <b-td rowspan="2">{{ value.tcp_date | moment('MMM D') }}, {{ value.tcp_date | moment('YYYY') }}</b-td>

                        <b-td rowspan="2" v-if="$parent.moduleRole('delete_payment') && $parent.moduleRole('edit_payment')">
                          <b-button
                            class="btn-icon"
                            variant="outline-warning"
                            v-b-tooltip.hover="'Edit Payment'"                                
                            @click="openModalEditPayment(value)"
                            v-if="$parent.moduleRole('edit_payment')"
                          ><i class="fas fa-pencil-alt"></i>
                          </b-button>

                          <b-button
                            class="btn-icon"
                            variant="outline-danger"
                            v-b-tooltip.hover="'Delete Payment'"                                
                            @click="deletePayment(value)"
                            v-if="$parent.moduleRole('delete_payment')"
                          ><i class="fas fa-trash-alt"></i>
                          </b-button>
                                                        
                        </b-td>
                      </b-tr>
                      <b-tr :key="'B' + index">
                        <template v-if="value.tcp_note != 'REFUND'">
                          <b-td>
                            <b-form-group label="Partner Share" label-for="comPartner" label-class="mb-1" class="mb-0">
                              <span>{{ $parent.formatRupiah(value.tcp_amount_partner) }}</span>
                            </b-form-group>
                          </b-td>
                          <b-td>
                            <b-form-group label="Company Share" label-for="comShare" label-class="mb-1" class="mb-0">
                              <span>{{ $parent.formatRupiah(value.tcp_amount_bu) }}</span>
                            </b-form-group>
                          </b-td>
                        </template>
                      </b-tr>
                    </template>
                    <b-tr class="bg-info text-white">
                      <b-th colspan="2" class="text-right">Remaining Payment</b-th>
                      <b-td colspan="4">{{$parent.formatRupiah(remaining)}}</b-td>                          
                    </b-tr>
                  </b-tbody>
                </b-table-simple>
              </div>
            </b-col>

            <b-col md=12 v-if="historyPayment && $parent.lengthArr(historyPayment.filter(x => x.tcp_loss_cost && x.tcp_loss_cost > 0))">
              <div class="detail-info">
                <span>Loss Cost History</span>
                <b-table-simple small bordered class="payment-table">
                  <b-thead>
                    <b-tr>
                      <b-th>Desc</b-th>
                      <b-th>Amount</b-th>
                      <b-th>Reason</b-th>
                      <b-th>Paid On</b-th>
                    </b-tr>
                  </b-thead>
                  <b-tbody>
                  <template v-for="(value, index) in historyPayment.filter(x => x.tcp_loss_cost && x.tcp_loss_cost > 0)">
                      <b-tr :key="'LCA' + index">
                        <b-td>{{ value.tcp_description }}</b-td>
                        <b-td>{{ $parent.formatRupiah(value.tcp_loss_cost) }}</b-td>
                        <b-td>{{ value.tcp_reason_loss_cost || '-' }}</b-td>
                        <b-td>{{ value.tcp_date | moment('MMM D') }}, {{ value.tcp_date | moment('YYYY') }}</b-td>
                      </b-tr>
                    </template>
                  </b-tbody>
                </b-table-simple>
              </div>
            </b-col>                 
          </b-row>

          <b-modal
            id="editProjWorth"
            title="Edit Project Worth"
            centered
            no-close-on-backdrop
          >
            <validation-observer v-slot="{ handleSubmit }" ref="VFormEditProjWorth">
              <b-form @submit.prevent="handleSubmit(editProjWorth())">
                <b-form-row>
                  <b-col md=6>
                    <b-form-group label="Old Project Worth" label-for="oldProjWorth">
                      <b-input-group prepend="Rp.">
                        <money id="oldProjWorth" disabled v-model="dataModal.tp_total_price" v-bind="$parent.maskCurrency" placeholder="cth: 600.00" class="form-control"></money>
                      </b-input-group>
                      <VValidate name="Old Project Worth" rules="required" v-model="dataModal.tp_total_price" />
                    </b-form-group>
                  </b-col>

                  <b-col md=6>
                    <b-form-group label="New Project Worth" label-for="newProjWorth">
                      <b-input-group prepend="Rp.">
                        <money id="newProjWorth" @input="setShare(dataModal)" v-model="dataModal.tp_total_price_new" v-bind="$parent.maskCurrency" placeholder="cth: 600.00" class="form-control"></money>
                      </b-input-group>
                      <VValidate name="New Project Worth" :rules="{required: 1, min_value: 1}" v-model="dataModal.tp_total_price_new" v-if="dataModal.tp_total_price_new < dataModal.tp_total_price" />
                    </b-form-group>
                  </b-col>

                  <template>
                    <b-col md="12">
                      <div class="border p-3 mb-3">
                        <h5 class="font-weight-bold">Update Shares</h5>
                        <b-form-row class="mb-2">
                          <b-col md="6">
                            <b-form-group label="Partner Share" label-for="proPartAddShare" class="mb-0">
                              <b-input-group prepend="Rp.">
                                <money id="proPartAddShare" @input="setShare(dataModal)" v-model="dataModal.tp_share_partner" v-bind="$parent.maskCurrency" placeholder="cth: 600.00" class="form-control"></money>
                              </b-input-group>                      

                            </b-form-group>
                          </b-col>
                          <b-col md="6">
                            <b-form-group label="Company Share" label-for="proCompAddShare" class="mb-0">
                              <b-input-group prepend="Rp.">
                                <money id="proCompAddShare" disabled v-model="dataModal.tp_share_bu" v-bind="$parent.maskCurrency" placeholder="cth: 600.00" class="form-control"></money>                          
                              </b-input-group>                      

                              <VValidate name="Company Share" v-model="dataModal.tp_share_bu" :rules="{min_value: 0, max_value: dataModal.tp_total_price_new}" />
                            </b-form-group>
                          </b-col>
                        </b-form-row>              
                      </div>
                    </b-col>
                  </template>
                </b-form-row>
              </b-form>
            </validation-observer>
            <template #modal-footer>
              <b-button size="md" variant="transparent" @click="closeModal('editProjWorth')">Cancel</b-button>
              <b-button size="md" variant="info" @click="editProjWorth()">Update</b-button>
            </template>
          </b-modal>

          <b-modal
            id="editPayment"
            title="Edit Payment"
            centered
            no-close-on-backdrop
          >
            <validation-observer v-slot="{ handleSubmit }" ref="VFormEditExistingPayment">
              <b-form @submit.prevent="handleSubmit(editPayment())">
                <b-form-row>
                  <b-col md=12>
                    <b-form-group label="Amount Payment" label-for="amountPayment">
                      <b-input-group prepend="Rp.">
                        <money id="amountPayment" :disabled="dataModalUpdate.tcp_note == 'BAILOUT'" @input="setPaymentShare(dataModalUpdate)" v-model="dataModalUpdate.tcp_amount" v-bind="$parent.maskCurrency" placeholder="cth: 600.00" class="form-control"></money>
                      </b-input-group>
                      <VValidate name="Amount Payment" :rules="{required: 1}" v-model="dataModalUpdate.tcp_amount" />
                    </b-form-group>
                  </b-col>

                  <template>
                    <b-col md="12">
                      <div class="border p-3 mb-3">
                        <h5 class="font-weight-bold">Payment Shares</h5>
                        <b-form-row class="mb-2">
                          <b-col md="6">
                            <b-form-group label="Partner Share" label-for="payPartAddShare" class="mb-0">
                              <b-input-group prepend="Rp.">
                                <money id="payPartAddShare" @input="setPaymentShare(dataModalUpdate)" v-model="dataModalUpdate.tcp_amount_partner" v-bind="$parent.maskCurrency" placeholder="cth: 600.00" class="form-control"></money>
                              </b-input-group>                      

                            </b-form-group>
                          </b-col>
                          <b-col md="6">
                            <b-form-group label="Company Share" label-for="payCompAddShare" class="mb-0">
                              <b-input-group prepend="Rp.">
                                <money id="payCompAddShare" disabled v-model="dataModalUpdate.tcp_amount_bu" v-bind="$parent.maskCurrency" placeholder="cth: 600.00" class="form-control"></money>                          
                              </b-input-group>                      

                              <VValidate name="Company Share" v-model="dataModalUpdate.tcp_amount_bu" :rules="{min_value: 0, max_value: dataModalUpdate.tcp_amount}" />
                            </b-form-group>
                          </b-col>
                        </b-form-row>              
                      </div>
                    </b-col>
                  </template>
                </b-form-row>
              </b-form>
            </validation-observer>
            <template #modal-footer>
              <b-button size="md" variant="transparent" @click="closeModal('editPayment')">Cancel</b-button>
              <b-button size="md" variant="info" @click="editPayment()">Update</b-button>
            </template>
          </b-modal>
        </b-tab>
        <b-tab title="Monitoring History">
          <template v-if="$parent.lengthArr(monitorHistory)">
            <b-row>
              <b-col lg="8" md="10">
                <div class="timeline">
                  <div class="timeline-box" v-for="(value, index) in monitorHistory" :key="index">
                    <div class="timeline-head">
                      <h4 class="timeline__title">{{ value.tss_date | moment('LLL') }}</h4>
                      <b-button v-if="value.tss_image" size="sm" variant="outline-info" @click="setLightBox(value.tss_image)">
                        <i class="fas fa-image"></i>
                        See Photo
                      </b-button>
                      <b-button
                        class="ml-2 btn-icon"
                        variant="outline-warning"
                        v-b-tooltip.hover="'Edit Photo'"
                        v-if="value.tss_image"
                        @click="showUpdateImage(value.tss_image, value.tss_id)"
                        size="sm"
                      ><i class="fas fa-pencil-alt"></i>
                      </b-button>
                      <p>Surveyor: {{ value.ms_name }}</p>
                    </div>

                    <div class="timeline-body">
                      <p>{{ value.tss_result }}</p>
                    </div>
                  </div>
                </div>
              </b-col>
            </b-row>
          </template>
          <template v-else>
            <h3>Monitor History is Empty.</h3>
          </template>
        </b-tab>
      </b-tabs>
    </b-card>
    <div class="media-overlay" :class="showSurveyImg ? 'show' : ''" @click.stop="showSurveyImg = false">
      <div class="media-content" @click.stop>
        <div class="media-dialog parent-wrapper">
          <b-img :src="$parent.uploader(detailImg)" />
          <b-button size="sm" @click.stop="showSurveyImg = false" class="close-button btn" variant="danger"><i class="fas fa-times"></i></b-button>
        </div>
      </div>
    </div>
    <b-modal id="updateImage" title="Update Image" centered no-close-on-backdrop>
      <validation-observer v-slot="{ handleSubmit }" ref="VFormUpdateImage">
        <b-form @submit.prevent="handleSubmit(submitUpdateImage())">
          <b-form-row>
            <b-col md=12>
              <b-form-group label-for="monPhoto">
                <template #label>Photo</template>
                <Uploader v-model="dataModal.tss_image" :usePreview="true" type="survey_result"/>
              </b-form-group>
            </b-col>
          </b-form-row>
        </b-form>
      </validation-observer>
      <template #modal-footer>
        <b-button size="md" variant="transparent" @click="closeModal('updateImage')">Cancel</b-button>
        <b-button size="md" variant="info" @click="submitUpdateImage()">Update</b-button>
      </template>
    </b-modal>
  </section>
</template>
<style scoped>
.c-pointer {
  cursor: pointer;
}

.fixed-top {
  align-self: flex-start;
  position: sticky;
  top: 6em;
  left: 0;  
  z-index: 10;
}
</style>
<script>
  import Gen from '@/libs/Gen'

  export default{
    props:{
      customerInformation: Object,
      followUpHistory: Array,
      surveyHistory: Array,
      remaining: Number,
      historyPayment: Array,
      monitorHistory: Array,      
      accountsReceivable: [Array, Object],
      received: [Array, Object],
      revenue: [Array, Object],    
      revenuePercent: [Array, Object],    
    },
    data() {
      return {
        detailImg: '',
        showSurveyImg: false,
        hasLossCost: false,
        hasBailout: false,
        dataModal: {},
        dataModalUpdate: {}
      }
    },
    methods: {
      setBailout(data){
        data['tcp_amount'] = 0
        data['tcp_amount_bu'] = 0
        this.hasLossCost = false
        data.tcp_loss_cost = 0
        data.tcp_reason_loss_cost = ''
      },
      setPricePartner(data){
        const hasil = data['tcp_amount'] - data['tcp_amount_bu']
        data['tcp_amount_partner'] = hasil
      },
      setPriceCompany(data){
        const hasil = data['tcp_amount'] - data['tcp_amount_partner']
        if(this.hasBailout || data['tcp_note'] == 'BAILOUT'){
          data['tcp_amount_bu'] = 0
        }else{
          data['tcp_amount_bu'] = hasil
        }
      },
      setLightBox(src){
        this.detailImg = src
        this.showSurveyImg = true
      },
      updatePayment(){
        this.$refs.VFormUpdatePayment.validate().then(success => {
          if(!success) return

          this.dataModal.tp_code = this.customerInformation.tp_code

          this.$swal({
          title: 'Submit Payment...',
          showConfirmButton: false,
          allowOutsideClick: false,
          didOpen: () => {
            this.$swal.showLoading()
          }
        })

        Gen.apiRest(
          '/do/' + this.$parent.modulePage,
          { data: {type: 'update-payment', ...this.dataModal, hasLossCost: this.hasLossCost, hasBailout: this.hasBailout} },
          'POST'
        ).then(res => {
          if(res){
              this.$swal({
                title: res.data.message,
                timer: 1500,
                icon: 'success',
              }).then(() => {
                this.$set(this, 'dataModal', {})
                this.$parent.apiGet()

                setTimeout(() => {                 
                  this.$nextTick(() => {
                    this.$refs["VFormUpdatePayment"].reset()
                  })
                 }, 300)
              })
          }
        }).catch(() => {
            this.$set(this, 'dataModal', {})
            this.$parent.apiGet()
        })

        })
      },
      showUpdateImage(tss_image, tss_id, key){
        this.dataModal = {tss_image, tss_id, key}
        this.$bvModal.show('updateImage')
      },
      submitUpdateImage(){
        this.$refs.VFormUpdateImage.validate().then(success => {
          if(!success) return

          this.$swal({
            title: 'Update image...',
            showConfirmButton: false,
            allowOutsideClick: false,
            didOpen: () => {
              this.$swal.showLoading()
            }
          })

          Gen.apiRest(
            '/do/' + this.$parent.modulePage,
            { data: {type: 'update-image-survey', ...this.dataModal} },
            'POST'
          ).then(res => {
            if(res){
              this.$swal({
                title: res.data.message,
                text: '',
                timer: 1500,
                icon: 'success'
              }).then(() => {
                this.$parent.apiGet()
                this.$bvModal.hide('updateImage')
              }).catch(() => {
                this.$parent.apiGet()
                this.$bvModal.hide('updateImage')
              })
            }
          })

        })
      },

      openModalEditProjWorth(data){        
        this.$set(this, 'dataModal', {})
        this.$set(this, 'dataModal', Object.assign({}, data, {tp_total_price_new: data.tp_total_price, additional_value: 0}))

        this.$bvModal.show('editProjWorth')        
      },

      openModalEditPayment(data){
        this.$set(this, 'dataModalUpdate', {})
        this.$set(this, 'dataModalUpdate', Object.assign({}, data))

        this.$bvModal.show('editPayment')
      },

      editProjWorth(){
        this.$refs.VFormEditProjWorth.validate().then(success => {
          if(!success) return 

          this.$swal({
            title: 'Update Project Worth...',
            showConfirmButton: false,
            allowOutsideClick: false,
            didOpen: () => {
              this.$swal.showLoading()
            }
          })

          Gen.apiRest(
            '/do/' + this.$parent.modulePage,
            { data: {type: 'update-project-worth', ...this.dataModal} },
            'POST'
          ).then(res => {
            if(res){
                this.$swal({
                  title: res.data.message,
                  timer: 1500,
                  icon: 'success',
                }).then(() => {
                  this.$set(this.customerInformation, 'tp_total_price', this.dataModal.tp_total_price_new)
                  this.$set(this, 'remaining', this.remaining + (this.dataModal.tp_total_price_new - this.dataModal.tp_total_price))
                  this.$set(this, 'accountsReceivable', res.data.data.accounts_receivable)
                  this.$set(this, 'revenue', res.data.data.revenue)
                  this.$set(this, 'revenuePercent', res.data.data.revenue_percent)

                  this.$parent.apiGet()
                  this.$bvModal.hide('editProjWorth')
                })
            }
          }).catch(() => {
              this.$bvModal.hide('editProjWorth')
          })        
        })
      },

      editPayment(){
        this.$refs.VFormEditExistingPayment.validate().then(success => {
          if(!success) return 

          this.$swal({
            title: 'Edit Payment...',
            text: 'This process will take a few minutes',
            showConfirmButton: false,
            allowOutsideClick: false,
            didOpen: () => {
              this.$swal.showLoading()
            }
          })

          Gen.apiRest(
            '/do/' + this.$parent.modulePage,
            { data: {type: 'update-existing-payment', ...this.dataModalUpdate} },
            'POST'
          ).then(res => {
            if(res){
                this.$swal({
                  title: res.data.message,
                  timer: 1500,
                  icon: 'success',
                }).then(() => {                                  
                  this.$parent.apiGet()
                  this.$bvModal.hide('editPayment')

                  this.$nextTick(() => {
                    this.$refs["VFormUpdatePayment"].reset()
                  })                  
                })
            }
          }).catch(() => {
              this.$bvModal.hide('editPayment')
          })        
        })
      },

      deletePayment(data){
        
        this.$swal({
          title: 'Delete this payment?',
          text: "You won't be able to restore this data!",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#d33',
          cancelButtonColor: '#3085d6',
          confirmButtonText: 'Yes, delete it!',
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1',
          },
          buttonsStyling: false,
        }).then(ok => {
          if(ok.isConfirmed){
            this.$swal({
              title: 'Delete Payment...',
              text: 'This process will take a few minutes',
              showConfirmButton: false,
              allowOutsideClick: false,
              didOpen: () => {
                this.$swal.showLoading()
              }
            })

            Gen.apiRest(
            '/do/' + this.$parent.modulePage,
            { data: {type: 'delete-payment', ...data} },
            'POST'
            ).then(res => {
              if(res){
                  this.$swal({
                    title: res.data.message,
                    timer: 1500,
                    icon: 'success',
                  }).then(() => {                                  
                    this.$parent.apiGet()

                    this.$nextTick(() => {
                      this.$refs["VFormUpdatePayment"].reset()
                    })                  
                  })
              }
            }).catch(() => {
                this.$swal({
                  title: "There is somthing wrong!",
                  icon: 'danger'
                })
            }) 
          }
        })
      },

      setShare(data){
        const hasilCompany = data['tp_total_price_new'] - data['tp_share_partner'] 

        data['tp_share_bu'] = hasilCompany      
      },

      setPaymentShare(data){
        const hasilCompany = data['tcp_amount'] - data['tcp_amount_partner']
        data['tcp_amount_bu'] = data['tcp_note'] == 'BAILOUT' ? 0 : hasilCompany
      },

      closeModal(id){
        this.$set(this, 'dataModal', {})
        this.$bvModal.hide(id)
      }
    },
    mounted(){
      setTimeout(() => {       
        if(!this.customerInformation) this.$parent.$router.push({ name: this.$parent.modulePage })      
       }, 800)
    }
  }
</script>
